import React from 'react';
import me from '../../assets/pictures/workingAtComputer.jpg';
import meNow from '../../assets/pictures/currentme.jpg';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Therzian Richard</h3>
            <br />
            <div className="text-block">
                <p>
                    I'm a tech enthusiast currently working at VITA School and
                    previously served as a Team Leader for FTTH at Telkom Akses.
                    I hold a diploma in Mechanical Engineering from the Sepuluh
                    Nopember Institute of Technology (ITS) and a bachelor's
                    degree in Computer Systems from STIKOM Surabaya.
                </p>
                <br />
                <p>
                    Thank you for taking the time to check out my portfolio. I
                    really hope you enjoy exploring it as much as I enjoyed
                    building it. If you have any questions or comments, feel
                    free to contact me using{' '}
                    <Link to="/contact">this form</Link> or shoot me an email at{' '}
                    <a href="mailto:therzian@gmail.com">therzian@gmail.com</a>
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>About Me</h3>
                <br />
                <p>
                    I'm a tech enthusiast who leans towards being introverted,
                    with a curiosity that has been driving me since my
                    university days. I love diving into the latest developments
                    in technology, especially in cybersecurity, where I’m eager
                    to learn about the challenges and solutions for protecting
                    data and privacy. I'm also really into UI/UX design,
                    exploring how we can make technology more user-friendly and
                    enjoyable. This curiosity fuels my passion for continuous
                    learning and inspires me to contribute to creating better
                    tech solutions.
                </p>
                <br />
                <div className="captioned-image">
                    <img src={me} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> My Curiosity Face 🤨 (during
                            university)
                        </sub>
                    </p>
                </div>

                <p>
                    My journey into programming began in high school, where I
                    found myself captivated by the logic and structure behind
                    coding. I started with boolean logic and assembly language
                    (machine language), fascinated by how these fundamental
                    concepts could bring ideas to life through technology. It
                    was like learning a new language, one that allowed me to
                    communicate with computers in a way that felt both
                    empowering and exciting. Back then, I was driven by an
                    aspiration to become a hacker, not for malicious intent, but
                    out of a genuine desire to understand systems deeply and
                    find ways to protect them. This curiosity about the inner
                    workings of technology led me to explore various programming
                    languages and concepts, laying a solid foundation for my
                    future studies in computer science.
                </p>
                <br />
                <p>
                    As I progressed through my education, my interest shifted
                    from the thrill of hacking to a broader appreciation for
                    technology's role in our lives. I became particularly drawn
                    to cybersecurity, wanting to understand the critical
                    importance of protecting information in an increasingly
                    digital world. This shift also sparked my passion for UI/UX
                    design, as I realized that a seamless user experience is
                    essential in making technology accessible and enjoyable for
                    everyone.
                </p>
                <br />
                <p>
                    Today, I continue to embrace my journey as a lifelong
                    learner, driven by my passion for technology and a desire to
                    contribute positively to the digital landscape. Whether it's
                    through creating innovative solutions or improving user
                    experiences, I strive to make a meaningful impact in the
                    tech world.
                </p>
                <br />
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>My Hobbies</h3>
                        <br />
                        <p>
                            Beyond my work in software, I have a variety of
                            hobbies that I enjoy in my free time. One of my
                            passions is running a{' '}
                            <Link to="https://www.youtube.com/@therzian">
                                YouTube channel
                            </Link>{' '}
                            , where I explore topics that interest me and share
                            my insights with others. I also have a deep
                            appreciation for music; with the rapid advancements
                            in AI technology, I've found a unique way to combine
                            my love for music and innovation. Using Suno AI, I
                            create lyrics and compose songs, allowing me to
                            express my creativity in new and exciting ways.
                        </p>
                        <br />
                        <p>
                            In addition to my interests in music and technology,
                            I’m also an animal lover, particularly when it comes
                            to dogs. I have a husky named Michelle, who is not
                            just a pet but also a cherished companion. She
                            brings joy and warmth to my life, serving as a
                            wonderful escape from the pressures of work and the
                            demands of various projects. Whether we’re going for
                            walks or simply relaxing at home, Michelle provides
                            the perfect balance to my busy life, reminding me of
                            the simple joys that come with companionship.
                        </p>
                        <br />
                        <p>
                            These hobbies not only enrich my life but also help
                            me unwind and recharge, allowing me to approach my
                            work with renewed energy and creativity.
                        </p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={meNow} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> Me, November 2023
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    Thanks for reading about me! I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer.
                    <br />
                    If you have any questions or comments I would love to hear
                    them. You can reach me through the{' '}
                    <Link to="/contact">contact page</Link> or shoot me an email
                    at{' '}
                    <a href="mailto:therzian@gmail.com">therzian@gmail.com</a>
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
