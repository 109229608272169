import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Telkom Akses</h1>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>GPON Engineer </h3>
                        <b>
                            <p>2012-2023</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <ul>
                    <li>
                        <p>
                            Network Planning and Implementation: A GPON Engineer
                            designs and configures optical networks, setting up
                            DSLAM/MSAN devices (from ZTE, Nokia, Huawei,
                            Alcatel-Lucent) to efficiently deliver data from the
                            core network to end-users.
                        </p>
                    </li>
                    <li>
                        <p>
                            Network Maintenance and Performance Monitoring:
                            Engineers routinely monitor GPON network
                            performance, using diagnostic tools on GPON devices
                            to detect and resolve issues, ensuring stable
                            connections and high signal quality
                        </p>
                    </li>
                    <li>
                        <p>
                            Capacity Optimization and Bandwidth Management: They
                            optimize network capacity by adjusting GPON devices
                            to allocate bandwidth effectively, ensuring
                            consistent service quality for end-users.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>VITA School</h1>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Education Technology</h3>
                        <b>
                            <p>2023-Now</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <ul>
                    <li>
                        <p>
                            Application Development and Integration: Designs and
                            develops custom school applications using PHP,
                            Laravel, React, and JavaScript. Ensures seamless
                            integration of these applications with existing
                            school systems for functions like student
                            information management, e-learning platforms, and
                            digital grading systems.
                        </p>
                    </li>
                    <li>
                        <p>
                            Database Management and Optimization: Uses MySQL for
                            designing and managing databases, structuring data
                            to support school-specific needs such as attendance,
                            grade tracking, and curriculum management. Ensures
                            database security, scalability, and efficient data
                            retrieval for reporting and analytics.
                        </p>
                    </li>
                    <li>
                        <p>
                            Collaboration and Automation with Google Workspace:
                            Develops tools and automation scripts that leverage
                            Google Workspace (Sheets, Docs, and Classroom) to
                            streamline administrative tasks and facilitate
                            digital collaboration between teachers, students,
                            and administrators, enhancing productivity across
                            the school ecosystem.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.header}>
                <div style={styles.headerRow}>
                    <h1>
                        Web Developer <br />& Software Engineer
                    </h1>
                </div>
                <br />
                <div style={styles.headerRow}>
                    <h3>3D Portfolio Website</h3>
                    <b>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://therzian.my.id/'}
                        >
                            <h4>therzian.my.id</h4>
                        </a>
                    </b>
                </div>
            </div>

            <ul>
                <li style={styles.row}>
                    <p>
                        Designed, engineered, and deployed this portfolio
                        website from scratch using React, Three.js and AWS.
                        Enjoy the unique and immersive 90s inspired experience
                        by visiting therzian.my.id.
                    </p>
                </li>
            </ul>
            <div style={styles.headerRow}>
                <h3>VISTA (VITA Integrated Support & Technology Assistance)</h3>
                <b>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={'https://vista.vita.sch.id/'}
                    >
                        <h4>vista.vita.sch.id</h4>
                    </a>
                </b>
            </div>
            <ul>
                <li style={styles.row}>
                    <p>
                        Web application built with CodeIgniter 3 that
                        streamlines technology support within the school. It
                        allows staff and students to submit and track support
                        requests related to IT and classroom equipment, manage
                        user roles, and generate reports to analyze common
                        issues, ensuring efficient maintenance of the school's
                        technology infrastructure.
                    </p>
                </li>
            </ul>
            <div style={styles.headerRow}>
                <h3>BayoNet Internet Service Provider</h3>
                <b>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={'https://bayonet.biz.id/'}
                    >
                        <h4>bayonet.biz.id</h4>
                    </a>
                </b>
            </div>
            <ul>
                <li style={styles.row}>
                    <p>
                        The services are specifically designed for residential
                        users, enhancing their online experience. Additionally,
                        as a local provider, it may offer customer support that
                        is more familiar with the specific challenges faced by
                        Indonesian users, ensuring better service and
                        responsiveness.
                    </p>
                </li>
            </ul>
            <div style={styles.headerRow}>
                <h3>eBook VITA School</h3>
                <b>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={'https://ebooks.vitaschool.sch.id/'}
                    >
                        <h4>ebooks.vitaschool.sch.id</h4>
                    </a>
                </b>
            </div>
            <ul>
                <li style={styles.row}>
                    <p>
                        Digital library platform, providing access to a variety
                        of eBooks for students and staff. It features a
                        collection of educational and literary materials,
                        allowing users to search for and read eBooks online. The
                        site supports learning by offering resources that can
                        enhance the educational experience, making it easier for
                        students to find relevant reading materials
                    </p>
                </li>
            </ul>
            <div style={styles.headerRow}>
                <h3>Library VITA School</h3>
                <b>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={'https://library.vitaschool.sch.id/'}
                    >
                        <h4>library.vitaschool.sch.id</h4>
                    </a>
                </b>
            </div>
            <ul>
                <li style={styles.row}>
                    <p>
                        Online platform for the VITA School Library, which
                        provides access to both physical and digital library
                        resources. It serves various campuses of VITA School,
                        including Dove Campus and Lighthouse Campuses for
                        different education levels.
                    </p>
                </li>
            </ul>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
